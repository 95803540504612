import React from 'react';

interface DetailProps {
  details: string;
}

const Details: React.FunctionComponent<DetailProps> = ({ details }) => (
  <div className="flex flex-col h-full">
    <div className="font-bold bg-dstl-purple text-white px-2 py-1 mb-2">
      Details
    </div>
    <div className="flex-1 h-full overflow-y-auto">
      {details && (
        <div className="bg-light-purple p-2 text-sm whitespace-pre-wrap">
          {details}
        </div>
      )}
    </div>
  </div>
);

export default Details;
