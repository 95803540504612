/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Layout from '../components/layout';
import Details from '../components/recommendations/Details';
import Filters from '../components/recommendations/Filters';
import RecommendationsPanel from '../components/recommendations/RecommendationsPanel';
import SEO from '../components/seo';
import { FilterGroups, Recommendation } from '../types/Global';

interface Props {
  pageContext: {
    allRecommendationsJson: any[];
  };
}

const RecommendationsPage = ({ pageContext }: Props) => {
  const [filterInput, setFilterInput] = useState<string[]>([]);
  const [selectedRecommendation, setSelectedRecommendation] = useState<
    Recommendation | undefined
  >(undefined);

  const { allRecommendationsJson } = pageContext;
  const recommendations: Recommendation[] = allRecommendationsJson.map(
    (recommendationJSON) => ({
      id: recommendationJSON.id,
      title: recommendationJSON.title,
      details: recommendationJSON.details,
    })
  );

  const filterGroups: FilterGroups = {};
  allRecommendationsJson.forEach((recommendationJSON) => {
    Object.keys(recommendationJSON).forEach((key) => {
      if (key !== 'id' && key !== 'title' && key !== 'recommendation') {
        const [group, filter] = key.split('#');
        if (group && filter) {
          if (!filterGroups[group]) {
            filterGroups[group] = {};
          }
          if (!filterGroups[group][filter]) {
            filterGroups[group][filter] = false; // Not active by default
          }
        }
      }
    });
  });

  // If no filters selected - show no recommendations
  let filteredRecommendations: any[] = [];
  console.log('filter length: ', filterInput.length);
  if (filterInput && filterInput.length > 0) {
    // Otherwise start with everything
    filteredRecommendations = allRecommendationsJson;
    // Go through each filter group
    Object.keys(filterGroups).forEach((filterGroupKey) => {
      // If the filter group has ANYTHING chosen
      if (
        filterInput.some((filter) => filter.startsWith(`${filterGroupKey}#`))
      ) {
        // Only keep recommendations that have one of the selected filter values from this group in scope
        filteredRecommendations = filteredRecommendations.filter(
          (recommendation) =>
            Object.keys(recommendation).some(
              (key) =>
                key.startsWith(`${filterGroupKey}#`) &&
                filterInput.includes(key) &&
                recommendation[key] === 'x'
            )
        );
      }
    });
  }

  const filteredRecommendationIds = filteredRecommendations.map(
    (filteredRecommendation) => filteredRecommendation.id
  );

  const onClickRecommendation = (id: string) => {
    setSelectedRecommendation(
      recommendations.find((recommendation) => recommendation.id === id)
    );
  };
  if (filterInput.length === 0) {
    if (selectedRecommendation) {
      setSelectedRecommendation(undefined);
    }
  }

  return (
    <Layout pageHeader="Research Recommendations" moreInfo="recommendations">
      <SEO
        keywords={[`dstl`, `future`, `workforce`, `recommendations`]}
        title="Research Recommendations"
      />
      <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4 h-full flex flex-col">
        <div className="mb-8 text-center">
          This page allows the recommendations from the overall research project to be viewed
          (Nb. these are based on the analysis conducted by the research team and do not represent endorsed MOD policy positions).
          Use the filters on the left to find relevant
          recommendations, then select a recommendation to view the details.
        </div>
        <section className="flex-1 h-full overflow-y-auto">
          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 flex flex-col h-full overflow-y-auto">
            <div className="col-span-1 sm:col-span-3 h-full overflow-y-auto">
              <Filters
                filterGroups={filterGroups}
                filterInput={filterInput}
                setFilterInput={setFilterInput}
              />
            </div>
            <div className="col-span-1 sm:col-span-4 h-full overflow-y-auto">
              <RecommendationsPanel
                filteredRecommendationIds={filteredRecommendationIds}
                recommendations={recommendations}
                onClickRecommendation={onClickRecommendation}
                filterInput={filterInput.length}
              />
            </div>
            <div className="col-span-1 sm:col-span-5 h-full overflow-y-auto">
              <Details details={selectedRecommendation?.details || ''} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default RecommendationsPage;
