/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FilterGroups } from '../../types/Global';

interface FilterProps {
  filterGroups: FilterGroups;
  filterInput: string[];
  setFilterInput: (filters: string[]) => void;
}

const Filters: React.FunctionComponent<FilterProps> = ({
  filterGroups,
  filterInput,
  setFilterInput,
}) => {
  const [filterState, setFilterState] = useState<string[]>(filterInput);

  const filterClicked = (group: string, filter:string) => {
    let newState = filterState;
    if (filterState.includes(`${group}#${filter}`)) {
      newState = filterInput.filter(
        (item: string) => item !== `${group}#${filter}`
      );
    }
    else {
      newState = [
        ...filterInput,
        `${group}#${filter}`,
      ];
    }
    setFilterState(newState);
    setFilterInput(newState);
  }

  return (    
    <div className="flex flex-col h-full">
      <div className="font-bold bg-dstl-purple text-white px-2 py-1">Filters</div>
      <div className="flex-1 h-full overflow-y-auto">

        {filterGroups &&
          Object.keys(filterGroups).map((group) => (
            <div key={group}>
              <div className="font-bold bg-dark-blue text-white px-1 py-1 mt-2">
                {group}
              </div>
              {filterGroups[group] &&
                Object.keys(filterGroups[group]).map((filter) => (
                  <div
                    key={filter}
                    className="flex"
                  >
                    <label
                      htmlFor={filter}
                      className="w-full bg-light-gray px-1 py-1 hover:bg-dstl-crimson cursor-pointer"
                    >
                      <input
                        className="mr-2 text-dstl-crimson cursor-pointer"
                        type="checkbox"
                        checked={!!filterState.includes(`${group}#${filter}`)}
                        onChange={() => {filterClicked(group, filter);}}
                        id={filter}
                        name={filter}
                        value={filter}
                      />
                      <span
                        className="text-gray-800 text-sm font-bold cursor-pointer break-words"
                      >
                        {filter}
                      </span>
                    </label>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Filters;
