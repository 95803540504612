/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { Recommendation } from '../../types/Global';

interface RecommendationsPanelProps {
  recommendations: Recommendation[];
  filteredRecommendationIds: string[];
  onClickRecommendation: (id: string) => void;
  filterInput: number;
}

const RecommendationsPanel: React.FunctionComponent<RecommendationsPanelProps> = ({
  recommendations,
  onClickRecommendation,
  filteredRecommendationIds,
  filterInput,
}) => {
  const [selectedRecommendation, setSelectedRecommendation] = useState<
    string | undefined
  >(undefined);

  const recommendationClicked = (id: string) => {
    setSelectedRecommendation(id);
    onClickRecommendation(id);
  };

  if (filterInput === 0) {
    if (selectedRecommendation) {
      setSelectedRecommendation(undefined);
    }
  }
  const rec = (recommendation: Recommendation) => {
    let value;
    for (let i = 0; i < recommendations.length; i++) {
      if (filteredRecommendationIds.includes(recommendation.id)) {
        value = (
          <div
            key={recommendation.id}
            onClick={() => recommendationClicked(recommendation.id)}
            className={`${
              selectedRecommendation === recommendation.id
                ? 'bg-dstl-crimson'
                : 'bg-dstl-light-crimson'
            } hover:bg-dstl-crimson font-bold text-sm px-2 py-1 mb-1 cursor-pointer`}
          >
            {recommendation.title}
          </div>
        );
      }
    }

    return value;
  };
  return (
    <div className="flex flex-col h-full">
      <div className="font-bold bg-dstl-purple text-white px-2 py-1 mb-2">
        Recommendations
      </div>
      <div className="flex-1 h-full overflow-y-auto">
        {recommendations &&
          recommendations.map((recommendation) => rec(recommendation))}
      </div>
    </div>
  );
};

export default RecommendationsPanel;
